import { Box, Button, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
import { directCall, aytLogo120 } from '../../../images';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import StyledButton from '../StyledButton';
import Auth from '../../../auth/Auth';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../reducers';
import QuoteButton from '../QuoteButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    padding: '6px 20px',
    position: 'absolute',
    width: '100%',
    height: '100px',
    color: 'white',
    zIndex: 100,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 15px',
      height: '84px'
    }
  },
  menuButton: {
    '&:hover': {
      cursor: 'pointer'
    },
    color: '#3365A7',
    backgroundColor: 'white'
  },
  logo: {
    justifySelf: 'center',
    zIndex: 50,
    //marginRight: 70,
    [theme.breakpoints.down('xs')]: {
      marginRight: -20,
      height: '58px'
    }
  },
  link: {
    justifySelf: 'center',
    zIndex: 50,
    marginRight: 0,
    marginLeft: 0
  },
  phoneWrapper: {
    justifySelf: 'end'
  },
  phoneLink: {
    margin: 0,
    lineHeight: '100%'
  },
  authButtons: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

interface HeaderProps {
  onOpenMenu?: (e: any) => void;
  isMenuOpened?: boolean;
}

const Header: FC<HeaderProps> = ({ onOpenMenu, isMenuOpened }) => {
  const theme = useTheme();
  const classes = useStyles();

  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const auth = new Auth(history);

  const signIn = async () => {
    auth.login();
  };

  const signOut = async () => {
    const auth = new Auth(history);
    auth.logout();
  };

  return (
    <div className={classes.root}>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>
        {!isMenuOpened && (
          <MenuIcon
            data-cy="open-menu-btn"
            onClick={onOpenMenu}
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            aria-haspopup="true"
          />
        )}
        <QuoteButton />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <a href="/" className={classes.link}>
          <img data-cy="logo-img" src={aytLogo120} alt="allied yacht logo" className={classes.logo} />
        </a>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'right' }}>
        {matchesXs ? (
          <div className={classes.phoneWrapper} data-cy="direct_call_btn">
            <a href="tel:+19549186694" className={classes.phoneLink}>
              <img src={directCall} alt="direct call icon" />
            </a>
          </div>
        ) : (
          <Box marginLeft="auto" className={classes.authButtons}>
            {isAuthenticated ? (
              <Button color="primary" variant="contained" onClick={signOut}>
                Sign out
              </Button>
            ) : (
              <StyledButton color="success" variant="contained" onClick={signIn}>
                Sign in
              </StyledButton>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Header;
